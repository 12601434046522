<template>

  <header>
    <Navbar/>
  </header>

  <main>
    <router-view/>
  </main>

</template>

<style>
@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}


body {
  font-family: Montserrat, Helvetica, Arial, sans-serif;
  background-color: #F4F7FC;
}
h1, a {
  text-transform: uppercase;
}
.fw-600 {
  font-weight: 600;
}
</style>
<script>
import Navbar from "@/components/Navbar";
export default {
  components: {Navbar}
}
</script>
