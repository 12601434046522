<template>
  <div class="container my-5 text-center">
    <div class="row justify-content-center">
      <div class="col-12">
        <h1 class="fw-600 text-uppercase">
          Travailler efficacement avec <br>
          notre <span class="text-primary">nouvel outil de gestion</span>
        </h1>
      </div>
      <!-- div class="col-lg-6">
        <p class="py-4 text-secondary">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse laoreet suscipit mi, non aliquam felis dignissim ut. Aliquam ante justo, tempor vitae dignissim sit amet, ultrices in magna.
        </p>
      </div -->
      <div class="col-12 mt-4">
        <a href="/login" class="btn btn-primary fw-600 px-5 py-3 shadow-sm">
          Découvrez l'outil
        </a>
      </div>
      <div class="col-12 mt-5">
        <img src="../assets/home.png" alt="home" width="100%">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  components: {

  }
}
</script>
