import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import {auth as authMiddleware,guest} from "@/middlewares";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    meta:{
       middlewares:[authMiddleware]
    },
    // route level code-splitting
    // this generates a separate chunk (dashboard.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
  },
  {
    path: '/history',
    name: 'history',
    meta:{
       middlewares:[authMiddleware]
    },
    // route level code-splitting
    // this generates a separate chunk (history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "history" */ '../views/HistoryView.vue')
  },

  {
    path: '/history/:id',
    name: 'history-details',
    meta:{
      middlewares:[authMiddleware]
    },
    // route level code-splitting
    // this generates a separate chunk (history.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "history" */ '../views/HistoryDetails.vue')
  },

  {
    path: '/stats',
    name: 'stats',
    meta:{
      middlewares:[authMiddleware]
    },
    // route level code-splitting
    // this generates a separate chunk (stats.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "stats" */ '../views/StatsView.vue')
  },

  {
    path: '/login',
    name: 'login',
    meta:{
      middlewares:[guest]
    },
    // route level code-splitting
    // this generates a separate chunk (login.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "login" */ '../views/authentication/LoginView.vue')
  },
  {
    path: '/password/forgot',
    name: 'forgotPassword',
    // route level code-splitting
    // this generates a separate chunk (forgot.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "forgot" */ '../views/authentication/ForgotPasswordView.vue')
  },
  {
    path: '/password/reset',
    name: 'resetPassword',
    // route level code-splitting
    // this generates a separate chunk (reset.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "reset" */ '../views/authentication/ResetPasswordView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {
  if (to.meta.middlewares) {
    const middleware = Array.isArray(to.meta.middlewares)
        ? to.meta.middlewares
        : [to.meta.middlewares];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});
export default router
