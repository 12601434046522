<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light shadow-sm">
    <div class="container">
      <router-link class="navbar-brand" to="/">
        <span><i class="fa-solid fa-plane-departure me-2"></i>Catring</span>
      </router-link>
      <button class="no-print navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item me-0 me-lg-4">
            <router-link class="nav-link" to="/">
              Accueil
            </router-link>
          </li>
          <li class="nav-item me-0 me-lg-4">
            <router-link class="nav-link" to="/stats">
              Statistiques
            </router-link>
          </li>
          <li v-if="user" class="nav-item me-0 me-lg-4">
            <router-link class="nav-link" to="/history">
              Historique
            </router-link>
          </li>
          <li class="nav-item" v-if="isAuthUserReady">
            <router-link v-if="!user" class="btn btn-primary shadow-sm py-2 px-4" to="/login">
              Se connecter
            </router-link>
            <button v-if="user" class="btn btn-outline-primary py-2 px-4 text-uppercase" @click="logout()">
              Se déconnecter
            </button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
import {computed} from "vue";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

export default {
  name: "Navbar",
  setup(){

    const {getters,dispatch} = useStore();

    const user = computed(() => getters['getUser']);
    const isAuthUserReady = computed(() => getters['isAuthReady']);
    const router = useRouter();
    const logout = async () => {
      try {
         await dispatch('logout')
      }catch (err)
      {
          console.log(err.message)
      }finally {
         router.push('/')
      }

    }

    return {
      user,logout,isAuthUserReady
    }
  }
}
</script>

<style scoped>

</style>
