import { createStore } from 'vuex'

import {auth} from '../firebase/config'
import {
  signInWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail
} from 'firebase/auth'

const store =  createStore({
  state: {
    user: null,
    authIsReady:false,
  },
  getters: {
    getUser(state){
      return state.user;
    },
    isAuthReady(state){
      return state.authIsReady;
    },
  },
  mutations: {
    setUser(state,payload)
    {
      state.user = payload;
    },
    setAuthIsReady(state,payload)
    {
      state.authIsReady = payload;
    }
  },
  actions: {
    async signIn({commit}, {email,password}){
      const response = await signInWithEmailAndPassword(auth,email,password);

      if (response)
      {
          commit('setUser',response.user)
      }else {
        throw new Error('Could not complete login')
      }
    },
    async logout({commit}){
       await signOut(auth);

       commit('setUser',null)
    },
    async sendResetLink(context,email){
      await sendPasswordResetEmail(auth,email)
    },
    async resetPassword()
    {

    }
  },
  modules: {
  },

})


export default store;