import { createApp } from 'vue'
import "bootstrap/dist/css/bootstrap.css"
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import App from './App.vue'
import router from './router'
import store from './store'
import { configure } from 'vee-validate';
import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
    name: '_blank',
    specs: [
        'fullscreen=yes',
        'titlebar=yes',
        'scrollbars=yes'
    ],
    styles: [
        'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
        'https://unpkg.com/kidlat-css/css/kidlat.css'
    ],
    timeout: 1000, // default timeout before the print window appears
    autoClose: true, // if false, the window will not close after printing
    windowTitle: window.document.title, // override the window title
}

// Default values
configure({
    validateOnBlur: true, // controls if `blur` events should trigger validation with `handleChange` handler
    validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
    validateOnInput: true, // controls if `input` events should trigger validation with `handleChange` handler
    validateOnModelUpdate: true, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
});

library.add(fas)
import "bootstrap/dist/js/bootstrap"
import {onAuthStateChanged} from "firebase/auth";
import {auth} from "@/firebase/config";

const unsubscribe = onAuthStateChanged(auth,(user) => {
    store.commit('setUser',user)
    store.commit('setAuthIsReady',true)
    createApp(App).use(store).use(router).mount('#app')
    unsubscribe();
})

// Vue.use(VueHtmlToPaper, options);
