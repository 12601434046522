import store from "../store";


const auth = ({ next }) => {
    if (!store.getters['getUser'])
    {
        return  next({name: 'login'})
    }

    return next()
}

const guest = ({ next }) => {

    if (store.getters['getUser'])
    {
        return  next({name: 'dashboard'})
    }

    return next()
}

export {auth,guest}